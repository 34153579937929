<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'BatchCard',
	components: {},
	props: {
		batch: {
			type: Object,
			required: true,
		},
	},
	methods: {},
})
</script>

<template>
	<el-card class="box-card">
		<template #header>
			<div class="clearfix">
				<el-button
					style="float: right; padding: 3px 0"
					type="text"
					@click="$router.push({ name: 'estimateEdit', params: { id: batch.id } })"
				>
					Modifier
				</el-button>
				<h2>Informations</h2>
			</div>
		</template>
		<el-col class="batch-meta-labels">
			<el-row> Date: </el-row>
			<el-row> Numéro: </el-row>
			<el-row> Nom: </el-row>
		</el-col>
		<el-col class="batch-meta-values">
			<el-row> {{ batch.date }} </el-row>
			<el-row> {{ batch.number }}</el-row>
			<el-row> {{ batch.name }} </el-row>
		</el-col>
		<el-col class="batch-meta-labels">
			<el-row> Description: </el-row>
			<el-row> Type de section: </el-row>
			<el-row> Préfixe numéro:</el-row>
			<el-row> Longueur numéro:</el-row>
			<el-row> Méthode numéro:</el-row>
			<el-row> Coordonnées GPS:</el-row>
			<el-row> Tarif:</el-row>
		</el-col>
		<el-col class="batch-meta-values">
			<el-row> {{ batch.description }} </el-row>
			<el-row> {{ batch.sectionType }} </el-row>
			<el-row> {{ batch.numberPrefix }} </el-row>
			<el-row> {{ batch.numberLength }} </el-row>
			<el-row> {{ batch.numberMethod }} </el-row>
			<el-row> {{ batch.xCoordinate }} - {{ batch.yCoordinate }} </el-row>
			<el-row> {{ batch.estimMethod }} </el-row>
		</el-col>
	</el-card>
</template>

<style>
.batch-meta-labels,
.batch-meta-values {
	margin: 20px;
}
.batch-meta-labels .el-row,
.batch-meta-values .el-row {
	margin: 5px;
}
.batch-meta-values {
	padding-right: 50px;
}
</style>
