
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import BatchForm from '@/components/BatchForm.vue'
import { ApBatch } from '@/types'

export default defineComponent({
	name: 'BatchForm',
	components: {
		BatchForm,
	},
	props: {
		batchCategory: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			batch: {
				id: uuidv4(),
				batchType: this.batchCategory === 'cubage' ? 'cubage-detailed' : 'estimate',
				number: '',
				name: '',
				description: '',
				numberPrefix: '',
				numberLength: 3,
				date: '',
			},
		}
	},
	methods: {
		save (batchData: ApBatch): void {
			this.$store.dispatch('upsertBatch', batchData)
			this.$router.push({ name: `${this.batchCategory}s` })
		},
		cancel (batchId: string): void {
			this.$router.push({ name: this.batchCategory, params: { id: batchId } })
		},
	},
})
