
import { defineComponent } from 'vue'
import { getBatchCategoryFromType } from '@/tools'
import { ApBatch } from '@/types'

export default defineComponent({
	name: 'BatchList',
	props: {
		batchs: {
			type: Array,
			required: true,
		},
	},
	emits: ['batch-selected'],
	methods: {
		rowClick (row: ApBatch): void {
			this.$emit('batch-selected', getBatchCategoryFromType(row.batch_type), row.id)
		},
	},
})
