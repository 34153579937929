<script lang="ts">
import { defineComponent } from 'vue'
import BatchList from '@/components/BatchList.vue'
import { ApBatchWithSite } from '@/types'

export default defineComponent({
	name: 'ApBatchs',
	components: {
		BatchList,
	},
	props: {
		batchCategory: {
			type: String,
			required: true,
		},
	},
	computed: {
		batchs (): ApBatchWithSite[] {
			const batchs = this.$store.getters.getBatchsByType(this.batchCategory)
			batchs.forEach((b: ApBatchWithSite) => {
				if (b.site_id) {
					const site = this.$store.getters.getSite(b.site_id)
					if (site) {
						b.site_name = site.name
					}
				}
			})
			return batchs
		},
	},
	methods: {
		selectBatch (batchCategory: string, id: string): void {
			this.$router.push({ name: batchCategory, params: { id } })
		},
	},
})
</script>

<template>
	<batch-list :batchs="batchs" @batch-selected="selectBatch"></batch-list>
	<el-button type="primary" @click="$router.push({ name: batchCategory + 'Add' })">Ajouter</el-button>
</template>
