
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'BatchForm',
	props: {
		batch: {
			type: Object,
			required: true,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['submit', 'cancel'],
	data () {
		return {
			localBatch: JSON.parse(JSON.stringify(this.batch)),
		}
	},
	methods: {
		onSubmit (): void {
			this.$emit('submit', this.localBatch)
		},
		onCancel (): void {
			this.$emit('cancel', this.localBatch.id)
		},
	},
})
