
import { defineComponent } from 'vue'
import BatchList from '@/components/BatchList.vue'
import { ApBatchWithSite } from '@/types'

export default defineComponent({
	name: 'ApBatchs',
	components: {
		BatchList,
	},
	props: {
		batchCategory: {
			type: String,
			required: true,
		},
	},
	computed: {
		batchs (): ApBatchWithSite[] {
			const batchs = this.$store.getters.getBatchsByType(this.batchCategory)
			batchs.forEach((b: ApBatchWithSite) => {
				if (b.site_id) {
					const site = this.$store.getters.getSite(b.site_id)
					if (site) {
						b.site_name = site.name
					}
				}
			})
			return batchs
		},
	},
	methods: {
		selectBatch (batchCategory: string, id: string): void {
			this.$router.push({ name: batchCategory, params: { id } })
		},
	},
})
