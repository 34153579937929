<script lang="ts">
import { defineComponent } from 'vue'
import { getBatchCategoryFromType } from '@/tools'
import { ApBatch } from '@/types'

export default defineComponent({
	name: 'BatchList',
	props: {
		batchs: {
			type: Array,
			required: true,
		},
	},
	emits: ['batch-selected'],
	methods: {
		rowClick (row: ApBatch): void {
			this.$emit('batch-selected', getBatchCategoryFromType(row.batch_type), row.id)
		},
	},
})
</script>

<template>
	<el-table :data="batchs" style="width: 100%" @row-click="rowClick">
		<el-table-column prop="date" label="Date" width="120" sortable></el-table-column>
		<el-table-column prop="number" label="Numéro" width="120" sortable></el-table-column>
		<el-table-column prop="name" label="Name" width="240" sortable></el-table-column>
		<el-table-column prop="siteName" label="Chantier" width="180" sortable></el-table-column>
	</el-table>
</template>
