
import { defineComponent } from 'vue'
import BatchCard from '@/components/BatchCard.vue'
import WoodlogsCard from '@/components/WoodlogsCard.vue'
import { ApBatch, ApWoodlogWithTrunkInfo } from '@/types'

export default defineComponent({
	name: 'ApBatch',
	components: {
		BatchCard,
		WoodlogsCard,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data () {
		return {}
	},
	computed: {
		batch (): ApBatch {
			return this.$store.getters.getBatch(this.id)
		},
		woodlogsWTI (): ApWoodlogWithTrunkInfo[] {
			return this.$store.getters.getBatchWoodlogsWTI(this.id)
		},
	},
})
