<script lang="ts">
import { defineComponent } from 'vue'
import BatchForm from '@/components/BatchForm.vue'
import { ApBatch } from '@/types'

export default defineComponent({
	name: 'BatchForm',
	components: {
		BatchForm,
	},
	props: {
		batchCategory: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			batch: this.$store.getters.getBatch(this.id),
		}
	},
	methods: {
		save (batchData: ApBatch): void {
			this.$store.dispatch('upsertBatch', batchData)
			this.$router.push({ name: this.batchCategory, params: { id: batchData.id } })
		},
		cancel (batchId: string): void {
			this.$router.push({ name: this.batchCategory, params: { id: batchId } })
		},
	},
})
</script>

<template>
	<batch-form :batch="batch" @submit="save"></batch-form>
</template>
