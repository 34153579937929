<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'BatchForm',
	props: {
		batch: {
			type: Object,
			required: true,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['submit', 'cancel'],
	data () {
		return {
			localBatch: JSON.parse(JSON.stringify(this.batch)),
		}
	},
	methods: {
		onSubmit (): void {
			this.$emit('submit', this.localBatch)
		},
		onCancel (): void {
			this.$emit('cancel', this.localBatch.id)
		},
	},
})
</script>

<template>
	<el-form ref="form" :model="batch" label-width="200px">
		<el-form-item label="Source">
			<el-select v-model="localBatch.siteId" placeholder="Type de cubage">
				<el-option
					v-for="site in $store.state.sites"
					:key="site.id"
					:label="site.name"
					:value="site.id"
				></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="Date">
			<el-col :span="11">
				<el-date-picker
					v-model="localBatch.date"
					type="date"
					placeholder="Choisissez une date"
					style="width: 100%"
					format="YYYY-MM-DD"
				></el-date-picker>
			</el-col>
		</el-form-item>
		<el-form-item label="Numéro">
			<el-input v-model="localBatch.number"></el-input>
		</el-form-item>
		<el-form-item label="Nom">
			<el-input v-model="localBatch.name"></el-input>
		</el-form-item>
		<el-form-item label="Description">
			<el-input v-model="localBatch.description"></el-input>
		</el-form-item>
		<el-form-item label="Préfixe plaquettes">
			<el-input v-model="localBatch.numberPrefix"></el-input>
		</el-form-item>
		<el-form-item v-if="isNew && batch.batchType.includes('cubage')" label="Type">
			<el-select v-model="localBatch.batchType" placeholder="Type de cubage">
				<el-option label="Détaillé" value="cubage-detailed"></el-option>
				<el-option label="Echantillonage" value="cubage-sampling"></el-option>
				<el-option label="Pile" value="cubage-stack"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item v-if="batch.batchType.includes('estimate')" label="Type">
			<el-select v-model="localBatch.estimMethod" placeholder="Méthode d'estimation">
				<el-option
					v-if="batch.batchType.includes('estimate-double')"
					label="Chaudé"
					value="CHAUDE"
				></el-option>
				<el-option v-if="batch.batchType.includes('estimate-simple')" label="Algan" value="ALGAN"></el-option>
				<el-option
					v-if="batch.batchType.includes('estimate-simple')"
					label="Fribourg"
					value="FRIBOURG"
				></el-option>
				<el-option v-if="batch.batchType.includes('estimate-simple')" label="Vaud" value="VAUD"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="Format numéro">
			<el-input-number v-model="localBatch.numberLength" :min="1" :max="9"></el-input-number>
		</el-form-item>
		<el-form-item>
			<el-button type="primary" @click="onSubmit">Enregistrer</el-button>
			<el-button @click="onCancel">Annuler</el-button>
		</el-form-item>
	</el-form>
</template>
