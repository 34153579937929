
import { defineComponent, ref, PropType } from 'vue'
import { ApWoodlogWithTrunkInfo } from '@/types'

export default defineComponent({
	name: 'WoodlogsCard',
	props: {
		batch: {
			type: Object,
			required: true,
		},
		woodlogsWti: {
			type: Array as PropType<Array<ApWoodlogWithTrunkInfo>>,
			required: true,
		},
	},
	setup () {
		/* eslint-disable-next-line */
		const woodlogTable = ref<any>(null)
		return {
			woodlogTable,
		}
	},
	data () {
		return {
			pos: 0,
			spanArr: [] as number[],
			trunkLabel: this.batch.type === 'estimate' ? 'tige' : 'grume',
		}
	},
	created () {
		this.getSpanArr(this.woodlogsWti)
	},
	mounted () {
		this.setCurrent(this.woodlogsWti[0])
	},
	methods: {
		setCurrent (row: ApWoodlogWithTrunkInfo): void {
			if (this.woodlogTable !== null) {
				this.woodlogTable.setCurrentRow(row)
			}
		},
		getSpanArr (data: ApWoodlogWithTrunkInfo[]): void {
			for (let i = 0; i < data.length; i++) {
				if (i === 0) {
					this.spanArr.push(1)
					this.pos = 0
				} else if (data[i].trunk.number === data[i - 1].trunk.number) {
					this.spanArr[this.pos] += 1
					this.spanArr.push(0)
				} else {
					this.spanArr.push(1)
					this.pos = i
				}
			}
		},
		// eslint-disable-next-line consistent-return
		spanMethod ({
			rowIndex,
			columnIndex,
		}: {
			rowIndex: number;
			columnIndex: number;
		}): { rowspan: number; colspan: number } | undefined {
			if ([0, 1, 2].includes(columnIndex)) {
				const _row = this.spanArr[rowIndex]
				const _col = _row > 0 ? 1 : 0
				return {
					rowspan: _row,
					colspan: _col,
				}
			}
		},
	},
})
